import segment from "@zenfolio/core-components/dist/utilities/segment";
import Cookies from "js-cookie";
import { browserName, browserVersion, deviceType, osName, osVersion } from "react-device-detect";
import { IUserInfo } from "../../../models/session";
import { isSegmentEnable } from "../../../utilities/accountAuthorizer";
import { checkIsPWAMode } from "../../../utilities/helpers";
import * as logging from "../../../utilities/logging";
import { getLoginMethodForSegment } from "../../../utilities/logging";
import { validateTokens } from "../../../utilities/token";
import { generateLoginLink } from "../../../utilities/url";
import { experience, getUserStatus } from "../../../utilities/user";

type DataType = number | string | boolean | bigint | symbol | null | undefined;

export interface ISegmentProperties {
  [key: string]: DataType;
}

interface DeviceInformation {
  browser_family: string;
  browser_version: string;
  os_family: string;
  os_version: string;
  device_type: string;
}

const getDeviceInformation = () => {
  const deviceInfo: DeviceInformation = {
    browser_family: browserName,
    browser_version: browserVersion,
    device_type: deviceType,
    os_family: osName,
    os_version: osVersion,
  };
  return deviceInfo;
};
type methodName = Extract<keyof typeof segmentCommon, string>;

const getGASessionId = () => Cookies.get("gtm_ga_session_id");

const getLogName = (name: methodName) => {
  return "Segment." + name;
};

const logError = (name: methodName, error: unknown) => {
  logging.error(`${getLogName(name)} has error:`, error);
};

const logCompleted = (name: methodName, extras?: string, ...params: unknown[]) => {
  logging.log(`${getLogName(name)} completed${extras ? " " + extras : ""}`, ...params);
};

const init = () => {
  if (!isSegmentEnable()) return;

  try {
    segment.trackingMethods.segmentInit(process.env.REACT_APP_SEGMENT_KEY!);
    logCompleted("init");
  } catch (e) {
    logError("init", e);
  }
};

const getSegmentUserId = () => {
  return isSegmentEnable() && validateTokens(true);
};

const trackGroup = (userInfo: IUserInfo) => {
  const userId = getSegmentUserId();
  if (!userId) return;

  try {
    const deviceInfo = getDeviceInformation();
    const data = {
      name: userInfo.businessName,
      businessName: userInfo.businessName,
      gaSessionId: getGASessionId(),
      ...deviceInfo,
    };
    segment.trackingMethods.trackGroup(userId, data);
    logCompleted("trackGroup", "with userId: %s, data: %o", userId, data);
  } catch (e) {
    logError("trackGroup", e);
  }
};

const trackPageEvent = (input: { title: string }) => {
  const userId = getSegmentUserId();
  if (!userId) return;

  try {
    const isPWAMode: boolean = checkIsPWAMode();
    const deviceInfo = getDeviceInformation();
    const data = {
      ...input,
      NZ_PWA_Mode: isPWAMode,
      gaSessionId: getGASessionId(),
      ...deviceInfo,
    };
    segment.trackingMethods.trackPage(userId, data);
    logCompleted("trackPageEvent", "with userId: %s, data: %o", userId, data);
  } catch (e) {
    logError("trackPageEvent", e);
  }
};

const trackIdentify = (settings: IUserInfo) => {
  const userId = getSegmentUserId();
  if (!userId) return;

  try {
    const deviceInfo = getDeviceInformation();
    const data = {
      ...segment.mapping.mappingInput_editor_identity({
        settings: {
          ...settings,
          shootTypes: settings.shootTypes.map((st) => ({
            name: st,
            isSelected: true,
          })),
        },
        userId,
        getLoginMethodForSegment,
        getPhotographerStatusName: () => getUserStatus(settings),
        REACT_APP_SUB_NAKEDDOMAIN: process.env.REACT_APP_SUB_NAKEDDOMAIN,
      }),
      ...generateLoginLink(settings),
      gaSessionId: getGASessionId(),
      ...deviceInfo,
    };
    segment.trackingMethods.trackIdentify(userId, data);
    logCompleted("trackIdentify", "with userId: %s, data: %o", userId, data);
  } catch (e) {
    logError("trackIdentify", e);
  }
};

const trackAction = (actionName: string, properties: ISegmentProperties) => {
  const userId = getSegmentUserId();
  if (!userId) return;

  try {
    const isPWAMode: boolean = checkIsPWAMode();
    const deviceInfo = getDeviceInformation();
    const data = {
      ...properties,
      isPWAMode,
      dl_version: "segment",
      experience,
      gaSessionId: getGASessionId(),
      ...deviceInfo,
    };
    segment.trackingMethods.trackAction(actionName, userId, data);
    logCompleted("trackAction", `[${actionName}] with userId: %s, data: %o`, userId, data);
  } catch (e) {
    logError("trackAction", e);
  }
};

const segmentCommon = {
  trackGroup,
  trackPageEvent,
  trackIdentify,
  trackAction,
  init,
  getSegmentUserId,
};

export default segmentCommon;
