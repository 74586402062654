import { FeatureCode } from "../models/session";
import Flagsmith, { FLAGS } from "./flagsmithClass";

export const checkAuthorizedFeatureBool = (
  features: Partial<Record<FeatureCode, boolean>> | undefined,
  authorizedFeature: FeatureCode
) => {
  return features && features !== null && features[authorizedFeature];
};

export const isPaypalPaymentEnabled = (features: Partial<Record<FeatureCode, boolean>> | undefined) => {
  return checkAuthorizedFeatureBool(features, FeatureCode.ECOM_PAYPAL);
};

export const isFlagSmithEnabled = () => process.env.REACT_APP_ENABLE_FLAGSMITH === "true";

export const isSegmentEnable = () => {
  if (isFlagSmithEnabled()) {
    return Flagsmith.isEnabled(FLAGS.ENABLE_SEGMENT);
  }
  return process.env.REACT_APP_ENABLE_SEGMENT;
};