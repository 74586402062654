import {
  Flagsmith as CoreFlagsmith,
  Flagsmith_V2 as CoreFlagsmith_v2,
  PROJECT_CODES,
} from "@zenfolio/core-components/dist/utilities/flagsmith";
import api from "../api";
import { IUserInfo } from "../models/session";
import { getUserType } from "./user";

export enum FLAGS {
  ENABLE_SEGMENT = "enable_mobile_web_segment",
  ENABLE_AI_MOBILE_ONBOARDING_WEBSITE_CREATION = "enable_ai_mobile_onboarding_website_creation",
  ENABLE_NEW_GALLERY_ACCESS = "enable_new_gallery_access",
  ENABLE_MOBILE_ADVANCE_DASHBOARD = "enable_mobile_advance_dashboard",
  ENABLE_MOBILE_SUBSCRIBE_RECEIVE_NOTIFICATION = "enable_mobile_subscribe_receive_notification",
  ENABLE_NEW_ACTIVITY_MONITOR = "enable_new_activity_monitor",
  ENABLE_NEW_SUBSCRIPTION_AND_BILLING_SETTINGS = "enable_new_subscription_and_billing_settings",
  ENABLE_NEW_CHANGING_SUBSCRIPTION = "enable_new_changing_subscription",
  ENABLE_DASHBOARD_V2 = "enable_dashboard_v2",
}

const defaultValues: Record<FLAGS, "True" | "False"> = {
  [FLAGS.ENABLE_SEGMENT]: "True",
  [FLAGS.ENABLE_AI_MOBILE_ONBOARDING_WEBSITE_CREATION]: "False",
  [FLAGS.ENABLE_NEW_GALLERY_ACCESS]: "False",
  [FLAGS.ENABLE_MOBILE_ADVANCE_DASHBOARD]: "False",
  [FLAGS.ENABLE_MOBILE_SUBSCRIBE_RECEIVE_NOTIFICATION]: "False",
  [FLAGS.ENABLE_NEW_ACTIVITY_MONITOR]: "True",
  [FLAGS.ENABLE_NEW_SUBSCRIPTION_AND_BILLING_SETTINGS]: "False",
  [FLAGS.ENABLE_NEW_CHANGING_SUBSCRIPTION]: "False",
  [FLAGS.ENABLE_DASHBOARD_V2]: "False",
};

class FlagsmithClass {
  private _flagsmith: CoreFlagsmith | CoreFlagsmith_v2 | null = null;
  private isV2 = process.env.REACT_APP_ENABLE_FLAGSMITH_V2 === "true";

  public async identifyAsync(userInfo: IUserInfo) {
    let flagsmith = this._flagsmith;
    if (!flagsmith) {
      flagsmith = this.isV2
        ? new CoreFlagsmith_v2(defaultValues, PROJECT_CODES.EDITOR)
        : new CoreFlagsmith(defaultValues);
      this._flagsmith = flagsmith;
    }

    await flagsmith.identity(
      PROJECT_CODES.EDITOR,
      api.tools.getFeatures,
      userInfo.id,
      {
        user_type: getUserType(userInfo),
        plan_code: userInfo.planCode,
        newVersion: userInfo.featureFlagVersion,
        signup_medium: userInfo.identitySegment?.signupMedium || "",
      },
      api.tools.identityFeature
    );
  }

  public async updateAsync(userInfo: IUserInfo) {
    await this.identifyAsync(userInfo);
  }

  public async fetchFeatures() {
    if (!this.isV2) return;
    await this._flagsmith?.fetchFeatures(PROJECT_CODES.EDITOR, api.tools.getFeatures);
    return this._flagsmith;
  }

  public getFromLocalStore() {
    if (!this.isV2) return;
    this._flagsmith?.getFromLocalStore();
  }

  public getFeatures() {
    return this._flagsmith?.getFeatures();
  }

  public isEnabled(flag: FLAGS) {
    return !!this._flagsmith?.enable(flag);
  }

  public reset() {
    const flagsmith = this._flagsmith;
    if (flagsmith) {
      flagsmith.removeLocalStore();
      this._flagsmith = null;
    }
  }
}

const Flagsmith = new FlagsmithClass();

export default Flagsmith;
