import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ActivityMonitor from "./components/features/ActivityMonitor";
import ActivityMonitorNew from "./components/features/ActivityMonitorNew";
import Initializing from "./components/features/Initializing";
import LandscapeWarning from "./components/features/LandscapeWarning";
import { render } from "./components/features/Lazy/helpers";
import Loading from "./components/features/Loading";
import LoginUrlManager from "./components/features/Login/UrlManager";
import OnBoardingUrlManager from "./components/features/OnBoarding/UrlManager";
import RootToast from "./components/features/RootToast";
import SiteEditorHub from "./components/features/SiteEditorHub";
import TrialValidator from "./components/features/Trial/TrialValidator";
import useAppStartup from "./components/hooks/useAppStartup";
import useHidePendo from "./components/hooks/useHidePendo";
import useListenSWToPushSegment from "./components/hooks/useListenSWAction";
import AppRoutes, { isOnBoardingUrl, isV2OnboardingFlow } from "./Routes";
import { Dispatch, RootState } from "./store/store";
import { checkSubscription, onTogglePushNotification } from "./sw/serviceWorkerRegistration";
import { DecodeBase64 } from "./utilities/base64";
import { sendGA4AddedToHome } from "./utilities/bookmark";
import Flagsmith, { FLAGS } from "./utilities/flagsmithClass";
import { d2pEventSignUpKey, enableSitesGeneration } from "./utilities/onBoarding";
import { getInitialUrl, redirectToDesktopSite, redirectToInitialUrl, storeInitialUrl } from "./utilities/url";

const Login = React.lazy(() => import("./components/features/Login"));
const ResetPassword = React.lazy(() => import("./components/features/Login/ResetPassword"));

declare global {
  interface Window {
    subscribeNotification: { (): void } | null;
    checkSubscription: { (): void } | null;
  }
}

const mapState = (state: RootState) => ({
  userInfo: state.session.userInfo,
});

const mapDispatch = (dispatch: Dispatch) => ({
  updateUserProperties: (properties: Record<string, string>) => dispatch.session.updateUserPropertiesAsync(properties),
});

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;
type Props = StateProps & DispatchProps;

function MainApp(props: Props) {
  const { userInfo, updateUserProperties } = props;
  const navigate = useNavigate();
  useListenSWToPushSegment("click push notification");

  const redirectToOnboarding = (initialUrl?: string) => {
    if (userInfo && isV2OnboardingFlow(userInfo)) {
      redirectToDesktopSite("auto", OnBoardingUrlManager.prefixV2, true);
    } else {
      redirectToInitialUrl(
        navigate,
        "auto",
        !initialUrl || !isOnBoardingUrl(initialUrl) ? OnBoardingUrlManager.prefix : initialUrl,
        true
      );
    }
  };

  useEffect(() => {
    window.subscribeNotification = onTogglePushNotification;
    window.checkSubscription = checkSubscription;
    return () => {
      window.subscribeNotification = null;
      window.checkSubscription = null;
    };
  }, []);

  const initialized = useAppStartup(
    userInfo,
    LoginUrlManager.socialRoute,
    LoginUrlManager.resetPassword,
    true,
    () => {
      storeInitialUrl();
      return Promise.resolve(true);
    },
    (userId, url) => {
      const query = new URLSearchParams(url.search);

      if (query.get("ai") === "true") {
        enableSitesGeneration(userId);
      }

      const d2p = query.get("d2p");
      if (d2p) {
        try {
          const decoded = DecodeBase64(d2p);
          sessionStorage.setItem(d2pEventSignUpKey, decoded);
        } catch (e) {
          // ignore any errors
        }
      }
    },
    (userInfo) => {
      const { identitySegment } = userInfo;
      const initialUrl = getInitialUrl(true);
      sendGA4AddedToHome(identitySegment, updateUserProperties);
      if (!userInfo.onBoardingCompleted) {
        redirectToOnboarding(initialUrl);
      } else {
        redirectToInitialUrl(navigate, "auto", initialUrl);
      }
    }
  );

  useHidePendo(!userInfo?.onBoardingCompleted);

  return (
    <>
      <Loading />
      <RootToast />
      {initialized ? (
        userInfo ? (
          <>
            <LandscapeWarning />
            <SiteEditorHub />
            <AppRoutes userInfo={userInfo} />
            {!isOnBoardingUrl() && <TrialValidator />}
            {!isOnBoardingUrl() &&
              (Flagsmith.isEnabled(FLAGS.ENABLE_NEW_ACTIVITY_MONITOR) ? <ActivityMonitorNew /> : <ActivityMonitor />)}
          </>
        ) : (
          <Routes>
            <Route path={LoginUrlManager.route} element={render(Login)} />
            <Route path={LoginUrlManager.resetPassword} element={render(ResetPassword)} />
            <Route path="*" element={<Navigate to={LoginUrlManager.prefix} replace={true} />} />
          </Routes>
        )
      ) : (
        <Initializing />
      )}
    </>
  );
}

export default connect(mapState, mapDispatch)(MainApp);
