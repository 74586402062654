import { Unit } from "@zenfolio/core-components/dist/utilities/unit";
import { IIdentitySegment, SettingSocialConnectionTypes } from "../api/account/models";
import { RegionType } from "../api/subscription/models";
import { PartialRecord } from "../types";
import { ChargePeriod, ISubscription } from "./subscription";

export type ConnectionMethod = "email" | "google" | "facebook";

export enum PhotographerStatusCode {
  None = 0,
  Active = 1,
  Closed = 2,
  Purged = 3,
  Suspended = 5,
  SuspendedExtend = 6,
}

export const defaultUserStatus = PhotographerStatusCode.None;

export interface IAddress {
  streetName: string;
  addressLine2: string;
  city: string;
  zipCode: string;
  country: string;
  state: string;
}

export interface IUserInfo {
  id: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  isTrial: boolean;
  onBoardingCompleted: boolean;
  features: PartialRecord<FeatureCode, boolean>;
  businessName: string;
  email: string;
  chargePeriod: ChargePeriod;
  planCode: string;
  planTier: string;
  planFamily: string;
  planRegionType: RegionType;
  signUpDate: string;
  subscriptionId: string;
  trialDays: number;
  connectionMethod: ConnectionMethod;
  status: PhotographerStatusCode;
  shootTypes: string[];
  primaryShootType: string | undefined;
  version: string;
  hideSellingWelcomeTab: boolean;
  isDisplayVAT: boolean;
  vatName: string | null;
  subscriptionOverview: ISubscription[] | null;
  identitySegment: IIdentitySegment;
  photographerAlias: string;
  expiredDays: number;
  currency: string;
  useCustomDomain: boolean;
  customDomain: string | null;
  isCustomDomainActive: boolean;
  featureFlagVersion: string;
  isUserTrial: boolean;
  isSocial: boolean;
  socialConnection: SettingSocialConnectionTypes;
  sizeUnit: Unit;

  billingAddress: IAddress;
  cardBrand: string;
  cardHolderName: string;
  siteInitialPublishDate: string | null;
  last4CC: string;
}

export enum FeatureCode {
  UPLOAD_VIDEO_4K = "uploaD_VIDEO_4K",
  ECOM_PAYPAL = "ecoM_PayPal",
  BOOKING_BOOKME = "bookinG_BookMe",
  ECOM_REVENUE_REPORT = "ecoM_RevenueReport",
}
