import { AxiosResponse } from "axios";
import _ from "lodash";
import { ITrialExtendTasks, IUserExtendTask } from "../../models/account";
import { ConnectionMethod, IUserInfo, defaultUserStatus } from "../../models/session";
import { defaultChargePeriod } from "../../models/subscription";
import { StrictOmit } from "../../types";
import { formatDate } from "../../utilities/date";
import { ITrialExtendTasksDto, IUserExtendTaskDto, IUserInfoDto } from "./models";

function getConnectionMethod(userInfo: IUserInfoDto): ConnectionMethod {
  const connection = userInfo.socialConnection?.toLowerCase();
  if (connection) {
    if (connection.includes("google")) {
      return "google";
    }

    if (connection.includes("facebook")) {
      return "facebook";
    }
  }

  return "email";
}

export function map_UserInfoDto_To_IUserInfo(
  id: string,
  response: AxiosResponse<IUserInfoDto | void>
): StrictOmit<IUserInfo, "features"> {
  const userInfo = response.data!;
  const subscription = _.find(userInfo.subscriptionOverview, (item) => !!item.planPeriod)!;
  const signupDateOriginal = userInfo.identitySegment?.signupDate || subscription.dateCreated;
  const chargePeriod = subscription.planPeriod.chargePeriod || defaultChargePeriod;
  const signUpDate = formatDate(signupDateOriginal.toString());
  const planTier = subscription.tierName || "";
  const planFamily = subscription.productName || "";
  const subscriptionId = subscription.subscriptionId;
  const trialDays = subscription.trialDays || 0;
  const connectionMethod = getConnectionMethod(userInfo);
  const status = userInfo.status || defaultUserStatus;
  const vatName = userInfo.vatName || null;
  const hideSellingWelcomeTab = userInfo.hideSellingWelcomeTab || false;
  const isDisplayVAT = userInfo.isDisplayVAT || false;
  const shootTypes = _.chain(userInfo.shootTypes)
    .filter((st) => st.isSelected)
    .map((st) => st.name)
    .value();
  const primaryShootType = _.find(userInfo.shootTypes, (st) => !!(st.isSelected && st.isPrimary))?.name;
  const expiredDays = userInfo.expiredDays;
  const currency = userInfo.currency;
  const sizeUnit = userInfo.sizeUnit;
  const siteInitialPublishDate = userInfo.siteInitialPublishDate;

  return {
    id,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    countryCode: userInfo.countryCode,
    isTrial: userInfo.isUserTrial,
    onBoardingCompleted: userInfo.welcomeWizardCompleted,
    businessName: userInfo.businessName,
    email: userInfo.email,
    chargePeriod,
    planCode: userInfo.planCode,
    planTier,
    planFamily,
    signUpDate,
    subscriptionId,
    trialDays,
    connectionMethod,
    status,
    shootTypes,
    primaryShootType,
    version: userInfo.xAppVersion,
    hideSellingWelcomeTab,
    isDisplayVAT,
    vatName,
    subscriptionOverview: userInfo.subscriptionOverview || null,
    identitySegment: userInfo.identitySegment,
    photographerAlias: userInfo.photographerAlias,
    expiredDays,
    currency,
    sizeUnit,
    useCustomDomain: userInfo.useCustomDomain,
    customDomain: userInfo.customDomain,
    isCustomDomainActive: userInfo.isCustomDomainActive,
    featureFlagVersion: response.headers["x-feature-flag-version"],
    isUserTrial: userInfo.isUserTrial,
    isSocial: userInfo.isSocial,
    socialConnection: userInfo.socialConnection,
    billingAddress: userInfo.billingAddress,
    cardBrand: userInfo.cardBrand,
    cardHolderName: userInfo.cardHolderName,
    last4CC: userInfo.last4CC,
    planRegionType: userInfo.planRegionType,
    siteInitialPublishDate
  };
}

export function map_ITrialExtendTasksDto_To_ITrialExtendTasks(data: ITrialExtendTasksDto): ITrialExtendTasks {
  return {
    ...data,
    userExtendTasks: data.userExtendTasks.map(map_IUserExtendTaskDto_To_IUserExtendTask),
  };
}

export function map_IUserExtendTaskDto_To_IUserExtendTask(data: IUserExtendTaskDto): IUserExtendTask {
  return {
    id: data.id,
    extendTrialType: data.extendTrialType,
    extendTrialName: data.extendTrialName,
    done: data.done,
  };
}
